<template>
  <div class="container">
    <header-back name='订单详情'>
      <template slot="seachRight">
        <span @click="onExport"> 下载
          <van-icon name="down" />
        </span>
      </template>
    </header-back>
    <section class="top44">
      <div class="sta-cen mt16">
        <van-field
          v-model="phone"
          type='tel'
          clearable
          name="手机号码"
          label="手机号码"
          placeholder="手机号码"
        />
        <van-field
          name="radio"
          label="状态"
        >
          <template #input>
            <van-radio-group
              v-model="radio"
              direction="horizontal"
            >
              <van-radio
                icon-size="16px"
                checked-color="#94FF75"
                v-for="(item,index) in radioList"
                :key='index'
                :name="item.name"
              >{{item.value}}</van-radio>

            </van-radio-group>
          </template>
        </van-field>
        <van-field
          readonly
          name="calendar"
          :value="value"
          label="开始/结束日期"
          label-width='7em'
          placeholder="选择日期区间"
          @click="showCalendar = true"
        >
          <template
            #right-icon
            v-if="value"
          >
            <van-icon
              name="clear"
              color="#c8c9cc"
              @click.stop="onClearable"
            />
          </template>

        </van-field>
        <van-calendar
          type="range"
          :min-date="minDate"
          :color="variables.textColor"
          v-model="showCalendar"
          allow-same-day
          @confirm="onConfirm"
        >

        </van-calendar>
        <div class="div-btn">
          <van-button
            round
            type="primary"
            :color="variables.textColor"
            size="small"
            @click="onSave"
          >确定</van-button>
        </div>
      </div>
      <!-- <div class="sta-m mt16">
        <van-cell
          value=""
          label=""
        >
          <div slot="title">
            <div class="van-cell-text">
              <van-image
                width="22"
                height="22"
                :src='vanImg'
              /> &nbsp;&nbsp;总积分值
            </div>
          </div>
          <div slot="right-icon">
            <span class="van-text-color">{{proint}}</span>
          </div>
        </van-cell>
        <van-cell
          value=""
          label=""
        >
          <div slot="title">
            <div class="van-cell-text">
              <van-image
                width="22"
                height="22"
                :src='vanImg'
              /> &nbsp;&nbsp;总订单金额
            </div>
          </div>
          <div slot="right-icon">
            <span class="van-text-color">{{orderMoney}}</span>
          </div>
        </van-cell>
        <van-cell
          value=""
          label=""
        >
          <div slot="title">
            <div class="van-cell-text">
              <van-image
                width="22"
                height="22"
                :src='vanImg'
              /> &nbsp;&nbsp;总订单数
            </div>
          </div>
          <div slot="right-icon">
            <span class="van-text-color">{{orderNum}}</span>
          </div>
        </van-cell>
      </div> -->
      <van-pull-refresh
        v-model="refreshing"
        @refresh="onRefresh"
      >
        <van-list
          v-model="loading"
          :finished="finished"
          :offset='offset'
          finished-text=""
          @load="onLoad"
        >
          <div class="van-clearfix">
            <div class="sta-m ">
              <div
                class="sta-mm mt16"
                v-for="(item,index) in orderList"
                :key='index'
              >
                <van-cell
                  value=""
                  title="兑换物品"
                >
                  <div slot="right-icon">
                    <span>{{item.goodsName}}*{{item.goodsNumber}}</span>
                  </div>
                </van-cell>

                <van-cell
                  value=""
                  title="积分值"
                >
                  <div slot="right-icon">
                    <span class="van-text-color">{{item.actualPrice}}</span>
                  </div>
                </van-cell>
                <van-cell
                  value=""
                  title="核销面值"
                >
                  <div slot="right-icon">
                    <span class="van-text-red">￥{{item.checkSellPrice}}</span>
                  </div>
                </van-cell>
                <van-cell
                  value=""
                  title="订单号"
                >
                  <div slot="right-icon">
                    <span>{{item.orderSn}}</span>
                  </div>
                </van-cell>
                <van-cell
                  value=""
                  title="兑换号码"
                >
                  <div slot="right-icon">
                    <span>{{item.orderMobile }}</span>
                  </div>
                </van-cell>
                <van-cell
                  value=""
                  title="号码归属地"
                >
                  <div slot="right-icon">
                    <span>{{item.mobileLocation }}</span>
                  </div>
                </van-cell>

                <van-cell
                  value=""
                  title="工号"
                >
                  <div slot="right-icon">
                    <span>{{item.username}}</span>
                  </div>
                </van-cell>
                <van-cell
                  value=""
                  title="名称"
                >
                  <div slot="right-icon">
                    <span>{{item.nickname}}</span>
                  </div>
                </van-cell>
                <van-cell
                  value=""
                  title="订单时间"
                >
                  <div slot="right-icon">
                    <span>{{item.addTime}}</span>
                  </div>
                </van-cell>

                <van-cell
                  value=""
                  title="订单地址"
                  v-if="item.reciverAddress.length===1"
                >
                  <div
                    class="reason"
                    slot="right-icon"
                    style="max-width:64%"
                  >
                    <span>{{item.reciverAddress[0]}}</span>
                  </div>
                </van-cell>
                <van-cell
                  value=""
                  title="修改前地址"
                  v-if="item.reciverAddress.length>1"
                >
                  <div
                    class="reason"
                    slot="right-icon"
                    style="max-width:64%"
                  >
                    <span>{{item.reciverAddress[0]}}</span>
                  </div>
                </van-cell>
                <van-cell
                  value=""
                  title="修改后地址"
                  v-if="item.reciverAddress.length>1"
                >
                  <div
                    class="reason"
                    slot="right-icon"
                    style="max-width:64%"
                  >
                    <span>{{item.reciverAddress[1]}}</span>
                  </div>
                </van-cell>
                <van-cell
                  v-if="item.cancel"
                  value=""
                  title="撤单时间"
                >
                  <div slot="right-icon">
                    <span>{{item.cancelTime}}</span>
                  </div>
                </van-cell>
                <van-cell
                  v-if="item.cancel"
                  value=""
                  title="撤单原因"
                >
                  <div
                    class="reason"
                    slot="right-icon"
                    style="max-width:64%"
                  >
                    <span>{{item.cancelReason}}</span>
                  </div>
                </van-cell>
                <van-cell
                  value=""
                  title="客诉原因"
                >
                  <div
                    class="reason"
                    slot="right-icon"
                    style="max-width:64%"
                  >
                    <span>{{item.kswt}}</span>
                  </div>
                </van-cell>
                <van-cell
                  value=""
                  title="客诉处理结果"
                >
                  <div
                    class="reason"
                    slot="right-icon"
                    style="max-width:64%"
                  >
                    <span>{{item.dresult}}</span>
                  </div>
                </van-cell>
                <div
                  v-if="item.cancel"
                  class="div-tit"
                >撤单</div>
                <div
                  v-else
                  class="div-tit"
                >{{item.orderStatus | getOrderStatus()}}</div>
              </div>

            </div>
          </div>
          <div
            style="color: #969799;font-size: 14px;text-align: center;padding-bottom:20px"
            v-if="noData"
          >
            <span> 没有更多了</span>
          </div>
        </van-list>
      </van-pull-refresh>
    </section>
  </div>
</template>
<script>
import HeaderBack from "../../components/header-back";
import { Toast } from "vant";
import utils from "../../utils/index";

import variables from "@/styles/variables.scss";
import API from "../../api/http";

export default {
  components: { HeaderBack },
  data() {
    return {
      phone: "",
      value: "",
      showCalendar: false,
      proint: "234",
      orderMoney: 2345,
      orderNum: 23,
      vanImg: require("../../assets/image/person/order1.png"),
      orderList: [],
      radio: "0",
      radioList: [
        {
          value: "全部",
          name: "0",
        },
        {
          value: "未完成",
          name: "1",
        },
        // {
        //   value: "退款",
        //   name: "2",
        // },
        {
          value: "撤单",
          name: "2",
        },
        {
          value: "已完成",
          name: "3",
        },
        {
          value: "客诉",
          name: "5",
        },
        // {
        //   value: "未完成",
        //   name: "1",
        // },
        // {
        //   value: "撤单",
        //   name: "2",
        // },
        // {
        //   value: "未上报",
        //   name: "3",
        // },
        // {
        //   value: "处理中",
        //   name: "4",
        // },
        // {
        //   value: "成功",
        //   name: "5",
        // },
        // {
        //   value: "失败",
        //   name: "6",
        // },
      ],
      listQuery: {
        page: 1,
        status: "0",
      },
      loading: false,
      finished: true,
      refreshing: false,
      noData: false,
      offset: 50,
      startDate: "", //开始日期
      endDate: "", //结束日期
      minDate: new Date(2010, 0, 1),
    };
  },
  computed: {
    variables() {
      return variables;
    },
  },

  created() {
    this.setIsDate();
    this.onLoad();
  },
  methods: {
    getInit(val) {
      API.postOrderDetailList(this.listQuery)
        .then((res) => {
          let data = res.data;
          this.loading = false;

          if (data.list.length > 0) {
            let list = data.list.map((item) => {
              //增加多地址的展示
              let add = item.reciverAddress.split(";");
              return {
                ...item,
                reciverAddress: add,
              };
            });
            if (data.pages === 1) {
              this.finished = true;
              this.noData = true;
            }

            if (val) {
              this.refreshing = false;

              this.orderList = list;
            } else {
              this.orderList = this.orderList.concat(list);
            }

            this.listQuery.page++;
          } else {
            this.refreshing = false;
            this.finished = true;
            this.noData = true;
          }
        })
        .catch(() => {
          this.refreshing = false;
          this.loading = false;
          this.finished = true;
        });
    },
    onLoad() {
      if (this.refreshing) {
        this.orderList = [];
        this.refreshing = false;
      }
      console.log("onLoad");
      this.finished = false;
      this.loading = true;
      this.noData = false;
      this.getInit();
    },
    onRefresh() {
      //下拉刷星
      // 清空列表数据

      this.finished = false;
      this.refreshing = true;
      this.noData = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.listQuery.page = 1;
      this.orderList = [];
      this.getInit(1);
    },

    formatDate(date) {
      return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
    },
    onConfirm(date) {
      const [start, end] = date;
      this.showCalendar = false;

      this.startDate = utils.parseTime(start, "{y}-{m}-{d}") + " " + "00:00:00";

      this.endDate = utils.parseTime(end, "{y}-{m}-{d}") + " " + "23:59:59";

      this.value = `${this.formatDate(start)} - ${this.formatDate(end)}`;
    },
    setIsDate() {
      let start = new Date();
      let end = new Date();
      this.startDate = utils.parseTime(start, "{y}-{m}-{d}") + " " + "00:00:00";
      this.endDate = utils.parseTime(end, "{y}-{m}-{d}") + " " + "23:59:59";
      this.value = `${this.formatDate(start)} - ${this.formatDate(end)}`;
      this.listQuery.startDate = this.startDate;
      this.listQuery.endDate = this.endDate;
    },
    onClearable() {
      this.value = "";
      this.startDate = "";
      this.endDate = "";
    },

    onSave() {
      //确定
      let obj = {
        page: 1,
        status: this.radio,
        sort: "retail_price",
        order: "desc", //desc:将序  asc:升序
      };
      if (this.phone.trim()) {
        obj.mobile = this.phone;
      }
      if (this.startDate) {
        obj.startDate = this.startDate;
        obj.endDate = this.endDate;
      }
      this.listQuery = obj;

      this.onRefresh();
    },
    onExport() {
      //导出
      let data = {
        page: 1,
        status: this.radio,
        export: 1,
      };
      if (this.listQuery.startDate) {
        data.startDate = this.listQuery.startDate;
        data.endDate = this.listQuery.endDate;
      }
      if (this.phone.trim()) {
        data.mobile = this.phone;
      }

      API.exportOrderExport(data, "订单详情");
    },
  },
};
</script>
<style lang='scss' scoped>
@import "../../styles/variables.scss";
.container {
  padding: 0 15px;
  box-sizing: border-box;
}
.sta-cen {
  border-radius: 6px;
  overflow: hidden;
  background-color: #fff;
  .div-btn {
    text-align: center;
    padding: 10px 0;
    .van-button {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
  .van-radio {
    padding-bottom: 8px;
  }
}
.sta-m {
  padding-bottom: 20px;
}
.sta-mm {
  border-radius: 6px;
  overflow: hidden;
  background-color: #fff;
  .van-cell {
    color: #777;
  }
  .div-tit {
    font-size: 14px;
    text-align: center;
    padding: 10px 16px;
    color: #53d42d;
  }
}
.van-text-color {
  color: $textColor;
}
.van-text-red {
  color: $redColor;
}
</style>
